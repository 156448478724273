<!--商品-->
<template lang="">
<div style="height:100%;padding:0 20px 10px;box-sizing:border-box">
    <div class="selectionBar">
        <div class="flex flex-ai-c flex-wrap">
            <label>场景：</label>
            <el-input placeholder="搜索名称" v-model.trim="searchValue" :clearable="true" size="small" class="input-with-select" prefix-icon="el-icon-search" style="width:200px;" @input="curr=1;getList()"></el-input>
            <el-select v-model="select.cate_id" placeholder="分类" :clearable="true" size="small" @change="curr=1;getList()">
                <el-option v-for="(item,index) in typeList" :key="index" :label="item.cate_name" :value="item.id"></el-option>
            </el-select>
            <el-select v-model="select.brand_id" placeholder="品牌" :clearable="true" size="small" @change="curr=1;getList()">
                <el-option v-for="(item,index) in brandList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <el-button type="primary" size="small" @click="edit()" style="margin-left: auto;" v-if="isShow">新建</el-button>
        </div>
    </div>
    <!-- 表格 -->
    <div class="table" style="height:calc( 100% - 175px )">
        <el-table ref="table" :data="list" highlight-current-row :cell-style="{color:'#515a6e',borderColor:'#E8EAEC'}" :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}" @sort-change='getList()' height="calc( 100% - 41px )">
            <el-table-column prop="brand.name" label="品牌" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="category.cate_name" label="分类" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="name" label="名称" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="" label="图片" align="center">
                <template slot-scope="scope">
                    <el-image style="width: 50px; height: 50px" :src="getImgUrl(scope.row.image)" :preview-src-list="getImgUrl(scope.row.image,'arr')" fit="cover">
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline" style="font-size:50px"></i>
                        </div>
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column prop="unit.name" label="单位" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="price" label="价格" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="car_model" label="车型" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="number" label="编号" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="integral" label="购买所得积分" align="center" show-overflow-tooltip></el-table-column>
			<el-table-column label="上架/下架" align="center">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.is_show" :active-value="1" :inactive-value="2" @change="setSwitch( scope.row.id , scope.row.is_show )"></el-switch>
                </template>
            </el-table-column>
			<el-table-column label="操作" align="center" width="280" v-if="isShow">
                <template slot-scope="scope">
					<el-button plain type="primary" size="mini" @click="edit(scope.row)">编辑</el-button>
                    <el-button plain type="danger" size="mini" @click="handleDel(scope.row.id)">删除</el-button>
                    <!-- <el-button plain type="warning" size="mini" @click="show(scope.row)">统计</el-button> -->
                    <!-- <div class="flex flex-column">
                        <el-link type="primary" @click="show(scope.row)" :underline="false">商品统计</el-link>
                        <el-link type="success" @click="edit(scope.row)" :underline="false">编辑商品</el-link>
                        <el-link type="danger" @click="handleDel(scope.row.id)" :underline="false">删除商品</el-link>
                    </div> -->
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="paging">
            <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
        </div>
    </div>


    <!-- 新建编辑 -->
    <el-dialog :title="dialogTitle" :modal-append-to-body="false" :close-on-click-modal="false" :visible="dialogIsShow" width="60%" @close="dialogClose">
        <el-form :model="formData" ref="form" label-position="top" :show-message="false" :status-icon="true" class="alertForm">
            <el-form-item label="分类" prop="cate_id" required>
                <el-select v-model="formData.cate_id" placeholder="请选择" size="small">
                    <el-option-group v-for="group in typeList" :key="group.id" :label="group.cate_name">
                        <el-option v-for="item in group.children" :key="item.id" :label="item.cate_name" :value="item.id">
                        </el-option>
                    </el-option-group>
                </el-select>

                <!-- <el-select v-model="formData.cate_id" placeholder="请选择" size="small">
                    <el-option v-for="(item,index) in typeList" :key="index" :label="item.cate_name" :value="item.id"></el-option>
                </el-select> -->

            </el-form-item>
            <el-form-item label="品牌" prop="brand_id" required>
                <el-select v-model="formData.brand_id" placeholder="请选择" size="small">
                    <el-option v-for="(item,index) in brandList" :key="index" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="商品名称" prop="name" required>
                <el-input v-model.trim="formData.name" size="small" placeholder="请填写" :clearable="true"></el-input>
            </el-form-item>
            <el-form-item label="单位" prop="unit_id" required>
                <el-select v-model="formData.unit_id" placeholder="请选择" size="small">
                    <el-option v-for="(item,index) in unitList" :key="index" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="价格" prop="price" required>
                <el-input v-model.trim="formData.price" size="small" placeholder="请填写" :clearable="true"></el-input>
            </el-form-item>
            <el-form-item label="车型" prop="car_model">
                <el-input v-model.trim="formData.car_model" size="small" placeholder="请填写" :clearable="true"></el-input>
            </el-form-item>
            <el-form-item label="编号" prop="number">
                <el-input v-model.trim="formData.number" size="small" placeholder="请填写" :clearable="true"></el-input>
            </el-form-item>
            <el-form-item label="购买所得积分" prop="integral">
                <el-input v-model.trim="formData.integral" size="small" placeholder="请填写" :clearable="true"></el-input>
            </el-form-item>
            <el-form-item label="商品图片">
                <div class="photoBox">
                    <div class="photo flex flex-wrap">
                        <div class="" v-if="formData.image">
                            <el-image :src="getImgUrl(formData.image)" fit="cover" :preview-src-list="getImgUrl(formData.image,'arr')">
                            </el-image>
                            <i class="el-icon-remove" @click="deletePhoto('image')"></i>
                        </div>
                        <label v-if="!formData.image">
                            <i class="el-icon-plus"></i>
                            <input type="file" class="uploadphoto" multiple="multiple" :value="filevalue" style="display:none" @change="upload($event,'image')" />
                        </label>
                    </div>
                </div>
            </el-form-item>

        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogClose" size="small">取 消</el-button>
            <el-button type="primary" @click="handleSubmit" class="save" size="small">保 存</el-button>
        </div>
    </el-dialog>
</div>
</template>

<script>
export default {
    data: function() {
        return {
            searchValue: '', //导入input值
            select: {},
            list: [],
            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数
            filevalue: '',

            formData: {},
            dialogTitle: '',
            dialogIsShow: false,

            typeList: [],
            unitList: [],
            brandList: [],
            isShow:false,
        }
    },
    mounted: function() {
        if(sessionStorage.getItem('bureau_name')){
            this.isShow=false
        }else{
            this.isShow=true
        }
        this.getList();
        this.getType();
        this.getUnit();
        this.getBrand()
    },
    methods: {
        show(row){
            this.$router.push({
                path:'/goodsDet',
                query:{
                    id:row.id
                }
            })
        },
		//上架下架
        setSwitch: function(id, _switch) {
            var api = "/admin.product/show";
            this.http.post(api, {
                id,
                is_show: _switch,
            }).then(() => {
                this.$message({
                    message: '设置成功',
                    type: 'success'
                });
            });
        },
        edit: function(row = {}) {
            if (typeof row.id == 'undefined') {
                this.dialogTitle = "新建商品";
            } else {
                this.dialogTitle = "编辑商品";
                this.formData = {};
                this.formData = JSON.parse(JSON.stringify(row));
            }
            this.dialogIsShow = true
        },
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.http.post('/admin.product/edit', this.formData).then(() => {
                        this.dialogClose();
                        this.getList();
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1000
                        })
                    })
                }
            })
        },
        dialogClose() {
            this.dialogIsShow = false;
            this.formData = {};
            this.$refs.form.resetFields();
        },
        // 类型
        getType() {
            this.http.post('/admin.category/getList',{},false).then(re => {
                this.typeList = re.data
            })
        },
        // 单位
        getUnit() {
            this.http.post('/admin.product/getUnitList',{},false).then(re => {
                this.unitList = re.data
            })
        },
        // 品牌
        getBrand() {
            this.http.post('/admin.brand/getList',{},false).then(re => {
                this.brandList = re.data
            })
        },
        // 图片清空
        deletePhoto(uploadelement) {
            this.formData[uploadelement] = ''
        },
        // 附件和图片上传获取接口返回的路径
        upload: function(event, uploadelement) {
            let loads2 = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
                customClass: 'myLoading',
            });
            let files = event.target.files;
            for (var i = 0; i < files.length; i++) {
                var formData = new FormData();
                formData.append('pic', files[i]);
                this.http.post('/Upload/upFile', formData, true, true).then(re => {
                    loads2.close();
                    if (re.code == 200) {
                        this.filevalue = "";
                        this.$set(this.formData, uploadelement, re.data.path)
                    } else {
                        this.$message.error(re.msg);
                    }
                })
            }
        },
        // 图片转换
        getImgUrl(item, type) {
            var url
            if (type == 'arr') {
                url = [this.domain + item];
            } else {
                url = this.domain + item;
            }
            return url;
        },



        getList: function() {
            this.http.post('/admin.product/getList', {
                curr: this.curr,
                row: this.row,
                search: this.searchValue,
                cate_id:this.select.cate_id,
                brand_id:this.select.brand_id,
            }).then(re => {
                this.list = re.data.list;
                this.curr = re.data.curr;
                this.pages = re.data.pages;
                this.count = re.data.count;
                this.$nextTick(() => {
                    this.$refs.table.doLayout()
                })
            })
        },
        handleDel(id) {
            this.$confirm('此操作将永久删除该条信息, 是否继续', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.http.post('/admin.product/delete', {
                    id
                }).then(() => {
                    this.getList();
                    this.$message({
                        message: '成功删除',
                        type: 'success'
                    })
                })
            })
        },

        //翻页
        currentChange: function(curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function(row) {
            this.row = row;
            this.getList();
        },
    }
}
</script>
